'use client';

import * as React from 'react';
import { useSearchParams } from 'next/navigation';
import { Box, Center, Separator, Flex, Heading, Stack, Text, Link } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslations } from 'next-intl';
import { LoginUser, LoginUserSchema } from '@shared/zod-schemas';
import RegisterInput from '@/components/form/RegisterInput';
import { useI18nZodErrors } from '@/hooks/useI18nZodErrors';
import { login } from '@/app/actions';
import { useRouter } from '@/i18n/routing';
import { Button } from '@/components/ui/button';
import { PasswordInput } from '@/components/ui/password-input';
import { errorToast } from '@/utils/toast';
import GoogleOneTap from '@/components/auth/GoogleOneTap';
import AppleLogin from '@/components/auth/AppleLogin';

export function LoginForm() {
	const searchParams = useSearchParams();
	const callbackUrl = searchParams.get('callbackUrl');
	const router = useRouter();

	const t = useTranslations('Login');
	useI18nZodErrors();
	const {
		handleSubmit,
		register,
		formState: { errors, isSubmitting },
	} = useForm<LoginUser>({
		resolver: zodResolver(LoginUserSchema),
	});

	const onSubmit = async (data: LoginUser) => {
		const result = await login(data);
		if (result.error) {
			errorToast(t('error'), t('errorMessage'));
		} else {
			router.push(callbackUrl || '/app');
		}
	};

	return (
		<Flex minH="100vh" align="center" justify="center" bg="bg.baseMain" px={{ base: 4, md: 8 }}>
			<Stack gap={8} width="full" maxW="lg" py={{ base: 8, md: 12 }} px={{ base: 6, md: 10 }} align="center">
				<Stack align="center">
					<Heading textStyle="3xl" textAlign="center">
						{t('title')}
					</Heading>
				</Stack>
				<Box
					bg="bg.card"
					width="full"
					maxW={{ base: 'full', sm: 'md' }}
					boxShadow="lg"
					p={{ base: 6, md: 8 }}
					borderRadius="md"
				>
					<Stack gap={6}>
						<Flex gap={3} direction="column">
							<GoogleOneTap />
							<AppleLogin />
						</Flex>
						<Separator />
						<form onSubmit={handleSubmit(onSubmit)}>
							<Stack gap={4}>
								<RegisterInput<LoginUser>
									name="email"
									title="E-mail"
									register={register}
									error={errors.email}
									type="email"
								/>

								<RegisterInput<LoginUser>
									name="password"
									title={t('password')}
									register={register}
									error={errors.password}
									type="password"
									component={PasswordInput}
								/>

								<Stack gap={5} mt={2}>
									<Stack direction={{ base: 'column', sm: 'row' }} justify="end" width="full">
										<Link color="primaryDark.100" href="/forgot-password">
											{t('forgotPassword')}
										</Link>
									</Stack>
									<Button
										type="submit"
										color="neutralLight"
										bg="primaryDark.100"
										boxShadow="0px 12px 12px -4px hsla(136, 34%, 65%, 0.4)"
										borderRadius={8}
										fontFamily="gabarito"
										px={6}
										py={7}
										lineHeight={1.15}
										fontSize="18px"
										fontWeight="semibold"
										transition="all 0.2s ease"
										loading={isSubmitting}
										_hover={{ bg: 'primaryDark.200', borderColor: 'primaryDark.200' }}
									>
										{t('submit')}
									</Button>
									<Separator />
									<Center>
										<Text>{t('dontHaveAccount')}</Text>&nbsp;
										<Link color="primaryDark.100" href="/register">
											{t('register')}
										</Link>
									</Center>
								</Stack>
							</Stack>
						</form>
					</Stack>
				</Box>
			</Stack>
		</Flex>
	);
}
